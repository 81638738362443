const minifyControllerConfig = (config) => config
    .map(item => {
        let minifiedItem = {
            l: item.link
        }

        if (!item.isVisible) {
            minifiedItem.hmb = true
        }

        if (!item.isVisibleMobile) {
            minifiedItem.hmmb = true
        }

        if (item.visibleForRoles && item.visibleForRoles.length > 0) {
            minifiedItem.vfr = item.visibleForRoles
        }

        return minifiedItem
    })

export {minifyControllerConfig}
