async function updatePageData({editorSDK, appToken, pageRef, pageData}) {
    await editorSDK.pages.data.update(appToken, {pageRef, data: Object.assign({}, pageData, {indexable: false})})
}

async function rename({editorSDK, appToken, pageRef, title}) {
    await editorSDK.pages.rename(appToken, {pageRef, title})
}

async function updatePagePassword({editorSDK, appToken, pageRef, plainPassword}) {
    await editorSDK.pages.permissions.updatePagePassword(appToken, {pageRef, plainPassword})
}

async function removePagePassword({editorSDK, appToken, pageRef}) {
    await editorSDK.pages.permissions.removePagePassword(appToken, {pageRef})
}

async function addPage({editorSDK, appToken, pageTitle}) {
    const pageRef = await editorSDK.pages.add(appToken, {title: pageTitle})
    return pageRef
}

async function navigateToPageRef({editorSDK, appToken, pageRef}) {
    await editorSDK.pages.navigateTo(appToken, {pageRef})
}

function getPageData({editorSDK, appToken, pageRef}) {
    return editorSDK.pages.data.get(appToken, {pageRef})
}

function getCurrentPage({editorSDK, appToken}) {
    return editorSDK.pages.getCurrent(appToken)
}

function getHomePage({editorSDK, appToken}) {
    return editorSDK.pages.getHomePage(appToken)
}

export {
    addPage,
    rename,
    updatePageData,
    updatePagePassword,
    removePagePassword,
    navigateToPageRef,
    getPageData,
    getCurrentPage,
    getHomePage
}
