let applicationState = {
    isDeletingSinglePage: false,
    isResponsiveEditor: false,
    isADI: false
}

const setIsDeletingSinglePage = (isDeleting) => {
    applicationState = {
        ...applicationState,
        isDeletingSinglePage: isDeleting
    }
}


const setIsResponsiveEditor = (isResponsiveEditor) => {
    applicationState = {
        ...applicationState,
        isResponsiveEditor
    }
}

const setIsADI = (isADI) => {
    applicationState = {
        ...applicationState,
        isADI
    }
}

const setStaticsBaseUrl = (staticsBaseUrl) => {
    applicationState = {
        ...applicationState,
        staticsBaseUrl
    }
}

const getIsDeletingSinglePage = () => applicationState.isDeletingSinglePage
const getIsResponsiveEditor = () => applicationState.isResponsiveEditor
const getIsADI = () => applicationState.isADI
const getStaticsBaseUrl = () => applicationState.staticsBaseUrl

export {
    setIsDeletingSinglePage,
    getIsDeletingSinglePage,
    setIsResponsiveEditor,
    getIsResponsiveEditor,
    setIsADI,
    getIsADI,
    setStaticsBaseUrl,
    getStaticsBaseUrl
}
