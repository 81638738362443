import {sortBy} from 'lodash'
import * as routersService from './routers'
import * as menusWrapper from '../wrappers/menus'
import {MENU_IDS, DEFAULT_MENU_ORDER} from '../constants'
import {log} from '../../utils/monitoring'

const {SUB_MENU_ID, LOGIN_MENU_ID, LOGIN_ICONS_MENU_ID} = MENU_IDS

function getMembersAreaMenuIds() {
    return {
        members: SUB_MENU_ID,
        login: LOGIN_MENU_ID,
        icons: LOGIN_ICONS_MENU_ID
    }
}

// We have issues when menus are storing pointers to pages which does not exist
// As we don't know the root cause, this should clear such menu items so further actions don't crash
// From additional information in sentry we might find out the root cause
const isInOneOfTheRouters = ({item, privateRouter, publicRouter}) => {
    const publicPatterns = publicRouter.config.patterns || {}
    const privatePatterns = privateRouter.config.patterns || {}
    return !!publicPatterns[`/${item.link.innerRoute}`] || !!privatePatterns[`/${item.link.innerRoute}`]
}

const removeMenuItemsWithoutRouter = (menuId, allMenuItems, privateRouter, publicRouter) => {
    const validMenuItems = allMenuItems.filter(item => isInOneOfTheRouters({item, privateRouter, publicRouter}))
    if (validMenuItems.length < allMenuItems.length) {
        const invalidItems = allMenuItems.filter(item => validMenuItems.indexOf(item) < 0)
        const invalidItemsNames = invalidItems.map(i => i.label).join(', ')
        log(`Fixing menu ${menuId}: removing items which are not apparent in any router`, {tags: {removedItems: invalidItemsNames}})
    }
    return validMenuItems
}

// We have issues when two menu items, pointing to the same page are apparent
// Removing those duplications as we don't know the root cause...
const removeMenuItemsDuplications = ({items, menuId}) => {
    const newItems = []
    items.forEach(item => {
        const hasNoDuplicates = !newItems.find(newItem => newItem.link.innerRoute === item.link.innerRoute)
        if (hasNoDuplicates) {
            newItems.push(item)
        } else {
            log(`Fixing menu ${menuId}: removing item duplication`, {tags: {removedItem: item.label}})
        }
    })
    return newItems
}

async function maybeCleanUpMenus({editorSDK, appToken}) {
    const [{publicRouter, privateRouter}, membersMenuItems, loginMenuItems, iconsMenuItems] = await Promise.all([
        routersService.getMembersAreaRouters({editorSDK, appToken}),
        menusWrapper.getMenuItems({editorSDK, appToken, menuId: SUB_MENU_ID}),
        menusWrapper.getMenuItems({editorSDK, appToken, menuId: LOGIN_MENU_ID}),
        menusWrapper.getMenuItems({editorSDK, appToken, menuId: LOGIN_ICONS_MENU_ID}),
    ])

    const membersMenuItemsInRouter = removeMenuItemsWithoutRouter(SUB_MENU_ID, membersMenuItems, privateRouter, publicRouter)
    const loginMenuItemsInRouter = removeMenuItemsWithoutRouter(LOGIN_MENU_ID, loginMenuItems, privateRouter, publicRouter)
    const iconsMenuItemsInRouter = removeMenuItemsWithoutRouter(LOGIN_ICONS_MENU_ID, iconsMenuItems, privateRouter, publicRouter)

    const validMembersMenuItems = removeMenuItemsDuplications({items: membersMenuItemsInRouter, menuId: SUB_MENU_ID})
    const validLoginMenuItems = removeMenuItemsDuplications({items: loginMenuItemsInRouter, menuId: LOGIN_MENU_ID})
    const validIconsMenuItems = removeMenuItemsDuplications({items: iconsMenuItemsInRouter, menuId: LOGIN_ICONS_MENU_ID})
    const promises = []

    if (validMembersMenuItems.length < membersMenuItems.length) {
        promises.push(menusWrapper.updateMenuItems({editorSDK, appToken, menuId: SUB_MENU_ID, items: validMembersMenuItems}))
    }

    if (validLoginMenuItems.length < loginMenuItems.length) {
        promises.push(menusWrapper.updateMenuItems({editorSDK, appToken, menuId: LOGIN_MENU_ID, items: validLoginMenuItems}))
    }

    if (validIconsMenuItems.length < iconsMenuItems.length) {
        promises.push(menusWrapper.updateMenuItems({editorSDK, appToken, menuId: LOGIN_ICONS_MENU_ID, items: validIconsMenuItems}))
    }

    if (promises.length > 0) {
        await Promise.all(promises)
    }
}

function createMenuItem({page, urlOverride, publicRouter, privateRouter}) {
    const {pageData} = page
    const routerId = page.pageData.isPrivate ? privateRouter.id : publicRouter.id

    let innerRoute = urlOverride || pageData.pageUriSEO
    innerRoute = pageData.isPrivate ? innerRoute : '{userName}/' + innerRoute

    const link = {type: 'DynamicPageLink', routerId, innerRoute}
    return {type: 'BasicMenuItem', items: [], label: pageData.title, link}
}

function createMembersMenuItems({pages, publicRouter, privateRouter}) {
    return pages.map(page => {
        const menuItem = createMenuItem({page, urlOverride: page.urlOverride, publicRouter, privateRouter})
        return {
            ...menuItem,
            isVisible: page.showInMemberMenu === undefined ? true : page.showInMemberMenu,
            isVisibleMobile: page.showInMemberMenu === undefined ? true : page.showInMemberMenu,
        }
    })
}

function createLoginMenuItems({pages, publicRouter, privateRouter}) {
    return pages.filter(p => p.showInLoginMenu).map(page => {
        const menuItem = createMenuItem({page, urlOverride: page.urlOverride, publicRouter, privateRouter})
        return {
            ...menuItem,
            label: page.loginMenuTitle || page.pageData.title
        }
    })
}

function createIconsMenuItems({pages, publicRouter, privateRouter}) {
    return pages.filter(p => p.showInIconsMenu).map(page => {
        const menuItem = createMenuItem({page, urlOverride: page.urlOverride, publicRouter, privateRouter})
        return {
            ...menuItem,
            iconRef: {svgId: '2c36dc006cb94853a49daee7e821f642.svg', type: 'VectorImage'},
        }
    })
}

function sortMenuItems({menuItems, publicRouter, privateRouter}) {
    [publicRouter, privateRouter].forEach(router => {
        return router.config && router.config.patterns && Object.keys(router.config.patterns).forEach(pattern => {
            const menuItem = menuItems.filter(item => item.link.routerId === router.id && `/${item.link.innerRoute}` === pattern).pop()
            if (menuItem) {
                menuItem.order = router.config.patterns[pattern].appData.menuOrder || DEFAULT_MENU_ORDER
            }
        })
    })

    return sortBy(menuItems, item => item.order || DEFAULT_MENU_ORDER)
}

async function connectPagesToMenus({editorSDK, appToken, pages}) {
    const [{publicRouter, privateRouter}, oldMembersMenuItems, oldLoginMenuItems, oldIconsMenuItems] = await Promise.all([
        routersService.getMembersAreaRouters({editorSDK, appToken}),
        menusWrapper.getMenuItems({editorSDK, appToken, menuId: SUB_MENU_ID}),
        menusWrapper.getMenuItems({editorSDK, appToken, menuId: LOGIN_MENU_ID}),
        menusWrapper.getMenuItems({editorSDK, appToken, menuId: LOGIN_ICONS_MENU_ID}),
    ])

    const newMembersMenuItems = createMembersMenuItems({pages, publicRouter, privateRouter})
    const newLoginMenuItems = createLoginMenuItems({pages, publicRouter, privateRouter})
    const newIconsMenuItems = createIconsMenuItems({pages, publicRouter, privateRouter})

    const hasNewMembersMenuItems = newMembersMenuItems.length > 0
    const hasNewLoginMenuItems = newLoginMenuItems.length > 0
    const hasNewIconsMenuItems = newIconsMenuItems.length > 0

    const sortedMembersMenuItems = sortMenuItems({menuItems: oldMembersMenuItems.concat(newMembersMenuItems), publicRouter, privateRouter})
    const sortedLoginMenuItems = sortMenuItems({menuItems: oldLoginMenuItems.concat(newLoginMenuItems), publicRouter, privateRouter})
    const sortedIconsMenuItems = sortMenuItems({menuItems: oldIconsMenuItems.concat(newIconsMenuItems), publicRouter, privateRouter})

    return Promise.all([
        // Saving only members menu items as they include all of the items
        menusWrapper.saveMenuItemsForViewer(editorSDK, appToken, sortedMembersMenuItems),
        hasNewMembersMenuItems && menusWrapper.updateMenuItems({editorSDK, appToken, menuId: SUB_MENU_ID, items: sortedMembersMenuItems}),
        hasNewLoginMenuItems && menusWrapper.updateMenuItems({editorSDK, appToken, menuId: LOGIN_MENU_ID, items: sortedLoginMenuItems}),
        hasNewIconsMenuItems && menusWrapper.updateMenuItems({editorSDK, appToken, menuId: LOGIN_ICONS_MENU_ID, items: sortedIconsMenuItems}),
    ].filter(p => !!p))
}

export {createMenuItem, connectPagesToMenus, getMembersAreaMenuIds, maybeCleanUpMenus}
