import * as constants from '../constants'
import {head as head_} from 'lodash'
import {minifyControllerConfig} from '../../utils/config-minifier'
import {log} from '../../utils/monitoring'

async function createController(editorSDK, appToken, masterRef) {
    //todo - validate - "there can be only one"
    const compDef = constants.CONTROLLER_COMP_DEF
    return await editorSDK.components.add(appToken, {pageRef: masterRef, componentDefinition: compDef, customId: constants.CONTROLLER_COMP_CUSTOM_ID})
}

async function saveControllerConfig(editorSDK, appToken, config) {
    const controllerRef = await getController(editorSDK, appToken)
    const minifiedConfig = minifyControllerConfig(config)
    return await editorSDK.controllers.saveConfiguration(appToken, {controllerRef, config: minifiedConfig})
}

async function getController(editorSDK, appToken) {
    const controllers = await editorSDK.controllers.listControllers(appToken, {})
    return head_(controllers).controllerRef
}

async function getAllControllers(editorSDK, appToken) {
    return await editorSDK.controllers.listAllControllers(appToken)
}

async function removeConnectedComponents(editorSDK, appToken, controllerRef) {
    let componentsRefs = await editorSDK.controllers.listConnectedComponents(appToken, controllerRef)
    for (let componentRef of componentsRefs) {
        await editorSDK.components.remove(appToken, {componentRef})
    }
}

async function remove(editorSDK, appToken, controllerRef) {
    await editorSDK.components.remove(appToken, {componentRef: controllerRef.controllerRef})
}

async function wipeOut(editorSDK, appToken) {
    let controllersRefs = await getAllControllers(editorSDK, appToken)
    for (let controllerRef of controllersRefs) {
        //why don't I see a verticalMenu here?
        //because only verticalMenus currently rendered are returned... Huston we have a problem!!!
        await removeConnectedComponents(editorSDK, appToken, controllerRef)
        await remove(editorSDK, appToken, controllerRef)
    }
}

async function connectToController({editorSDK, appToken, connectToRef, controllerRef, role, connectionConfig = {}, isPrimary = true}) {
    try {
        await editorSDK.controllers.connect(appToken, {
            connectToRef,
            controllerRef,
            connectionConfig,
            role,
            isPrimary
        })
    } catch (e) {
        const tags = {platformMessage: e.toString()}
        const extra = {args: JSON.stringify({connectToRef, controllerRef, role, connectionConfig, isPrimary})}
        log('Failed to connect to controller', {tags, extra})
        throw e
    }

}

export {connectToController, createController, getController, wipeOut, saveControllerConfig}
